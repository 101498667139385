.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-font {
  font-family: 'Roboto Mono', monospace;
}

.green-icon {
  color: rgb(0, 193, 71);
}

.orange-icon {
  color: orange;
}

.red-icon {
  color: red;
}

.blue-icon {
  color: rgb(0, 123, 255);
}

.remove-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: black;
  transition: color 0.3s;
  margin-top: 4px;
}

.remove-button:hover {
  color: red; /* Change color on hover */
}

.clear-button {
  padding: 5px 10px;
  background-color: #ff8400;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: absolute;
  top: 10px;
  right: 10px;
}

.clear-button:hover {
  background-color: #d06c00; /* Change color on hover */
}

.compare-button {
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100px;
}

.compare-button:hover {
  background-color: rgb(0, 99, 205); /* Change color on hover */
}

.custom-modal-container {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  top: 0;
  transform: translateX(-50%);
  text-wrap: wrap;
  background-color: rgb(255, 0, 0);
  border-radius: 5px;
  text-align: center;
  overflow: visible;
  white-space: normal;
  padding: 0.5rem 1.5rem;
  transform: translateY(-50%);
  max-width: 300px;
}

.custom-modal-container.visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(50%);
  transition: transform 0.5s ease-in-out, opacity 0.3s ease-in;
}

.progress-container {
  width: 100%;
  height: 30px;
  position: relative;
}

.progress-bar {
  height: 3px;
  width: 0;
  background-color: #000000;
  position: absolute;
  bottom: 0px;
  left: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: width 2.5s ease-in-out;
}

.progress-bar.begin {
  width: 100%;
  transition: width 2.5s ease-in-out;
}

.divider {
  border-top: 1px solid #ccc;
  width: 100%;
  margin: 5px 0;
}